header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 3.75rem;
    background-color: black;
}

.logo {
    text-decoration: none;
    font-size: 1.875rem;
    cursor: pointer;
    color: white;
    font-weight: bold;
}

.favoritos{
    text-decoration: none;
    cursor: pointer;
    background-color: white;
    padding: 0.3rem 0.875rem;
    color: black;
    border-radius: 0.25rem;
}
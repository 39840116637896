.filme-info{
    margin: 0 auto;
    margin-top: 1.125rem;
    display: flex;
    flex-direction: column;
    max-width: 50rem;
    padding: 0 0.5rem ;
}

.filme-info h1 {
    margin: 0.875rem 0;
}

.filme-info img {
    border-radius: 0.5rem;
    width: 50rem;
    max-width: 100%;
    max-height: 21.25rem;
    object-fit: cover;
}

.filme-info h3 {
    margin-top: 0.875rem;
}

.filme-info span {
    margin: 0.5rem 0;
}

.area-buttons button {
    margin-right: 0.75rem;
    margin-top: 0.875rem;
    margin-left: 0;
    font-size: 1.25rem;
    border: 0;
    outline: none;
    padding: 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.5s;
}

.area-buttons button:hover {
    background-color: royalblue;
    color: white;
}

.area-buttons a {
    text-decoration: none;
    color: black;
    transition: all 0.5s;
}

.area-buttons a:hover {
    color: white;
}
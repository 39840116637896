.meus-filmes {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.meus-filmes h1{
    margin-bottom: 0.875rem;
}

.meus-filmes span {
    font-size: 1.125rem;
}

.meus-filmes ul {
    width: 75%;
}

.meus-filmes ul li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.875rem;
}

.meus-filmes div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.meus-filmes div a {
    text-decoration:  none;
    color: royalblue;
    margin-right: 0.875rem;
}
.lista-filmes {
    max-width: 50rem;
    margin: 0.875rem auto;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.875rem;
}

.lista-filmes article {
    width: 100%;
    background-color: white;
    padding: 1rem;
    border-radius: 0.25rem;
}

.lista-filmes strong {
    margin-bottom: 0.875rem;
    text-align: center;
    font-size: 1.375rem;
    display: block;
}

.lista-filmes img {
    width: 56.25rem;
    max-width: 100%;
    max-height: 21.25rem;
    object-fit: cover;
    display: block;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.lista-filmes a{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 0;
    font: 24px;
    background-color: royalblue;
    text-decoration: none;
    color: white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}